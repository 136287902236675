// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["AZ9krzURP", "MUGssMG2M"];

const variantClassNames = {AZ9krzURP: "framer-v-u8le6d", MUGssMG2M: "framer-v-qqnu1y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {duration: 0, type: "tween"}, MUGssMG2M: {delay: 0, duration: 1.8, ease: [0.46, 0.52, 0.16, 0.91], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"1": "AZ9krzURP", "2": "MUGssMG2M"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "AZ9krzURP"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "AZ9krzURP", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearyyxzjb = activeVariantCallback(async (...args) => {
await delay(() => setVariant("MUGssMG2M"), 100)
})

const onAppearikcxe8 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("AZ9krzURP"), 2000)
})

useOnVariantChange(baseVariant, {default: onAppearyyxzjb, MUGssMG2M: onAppearikcxe8})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-95NVP", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-u8le6d", className)} data-framer-name={"1"} data-highlight layoutDependency={layoutDependency} layoutId={"AZ9krzURP"} ref={ref} style={{backgroundColor: "var(--token-8d60b80e-df6b-42df-a8e7-c1a9c5676d4d, rgb(24, 24, 27))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} {...addPropertyOverrides({MUGssMG2M: {"data-framer-name": "2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1qxwwdh"} data-framer-name={"Shine"} layoutDependency={layoutDependency} layoutId={"PIKjjP7ss"} style={{background: "linear-gradient(270deg, var(--token-36bcd43b-d74f-4831-a7db-69797b63e728, rgb(39, 39, 42)) /* {\"name\":\"Zinc/800\"} */ 0%, rgba(26, 28, 29, 0) 100%)", opacity: 0.8}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-95NVP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-95NVP .framer-c3d9tj { display: block; }", ".framer-95NVP .framer-u8le6d { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 237px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 226px; will-change: var(--framer-will-change-override, transform); }", ".framer-95NVP .framer-1qxwwdh { flex: none; height: 1119px; left: -346px; overflow: hidden; position: absolute; top: -663px; width: 279px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-95NVP .framer-u8le6d { gap: 0px; } .framer-95NVP .framer-u8le6d > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-95NVP .framer-u8le6d > :first-child { margin-left: 0px; } .framer-95NVP .framer-u8le6d > :last-child { margin-right: 0px; } }", ".framer-95NVP.framer-v-qqnu1y .framer-1qxwwdh { bottom: -664px; left: unset; right: -277px; top: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 237
 * @framerIntrinsicWidth 226
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"MUGssMG2M":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerPJWFSg0wE: React.ComponentType<Props> = withCSS(Component, css, "framer-95NVP") as typeof Component;
export default FramerPJWFSg0wE;

FramerPJWFSg0wE.displayName = "Utilities/Loading frame";

FramerPJWFSg0wE.defaultProps = {height: 237, width: 226};

addPropertyControls(FramerPJWFSg0wE, {variant: {options: ["AZ9krzURP", "MUGssMG2M"], optionTitles: ["1", "2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerPJWFSg0wE, [])